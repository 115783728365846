import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { Text } from "../../Typography/Typo";
import PropTypes from "prop-types";

const DropzoneComponent = ({
  errors,
  description,
  setErrors,
  setFiles,
  files,
  maxFiles,
  maxSize,
  multiple,
  minSize,
  type,
  form,
  disabled,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    maxFiles: maxFiles,
    minSize: minSize,
    maxSize: maxSize,
    multiple: multiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles([]);
      rejectedFiles.forEach((fileCheck) => {
        fileCheck.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors(` Error: File is larger than 1 MB`);
          } else if (err.code === "file-invalid-type") {
            setErrors(` Error: File should be an image`);
          }
        });
      });
      if (rejectedFiles.length === 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  const thumbs =
    files?.length > 0 &&
    files?.map((file, index) => (
      <div style={thumb} key={index}>
        {!disabled && (
          <CancelIcon
            onClick={() => setFiles([])}
            style={{
              position: "absolute",
              top: "0%",
              left: "100%",
              zIndex: "99",
              transform: "translate(-50%, -50%)",
              color: "#8a8888",
            }}
          />
        )}

        <div style={thumbInner}>
          <img
            alt="PDF File"
            src={file.preview}
            style={img}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png";
            }}
          />
        </div>
      </div>
    ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (files.length > 0) {
        files.forEach((file) => {
          URL.revokeObjectURL(file.preview);
        });
      }
    },
    [files]
  );

  return (
    <DroppableContainer>
      <InnerContainer {...getRootProps()}>
        {!disabled && <input form={form} required {...getInputProps()} />}

        {files?.length === 0 && (
          <Text primary lighter small>
            {errors ? errors : description}
          </Text>
        )}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </InnerContainer>
    </DroppableContainer>
  );
};

DropzoneComponent.propTypes = {
  errors: PropTypes.any,
  description: PropTypes.string,
  setErrors: PropTypes.func,
  setFiles: PropTypes.func,
  files: PropTypes.array,
  id: PropTypes.string,
  amount: PropTypes.string,
  maxSize: PropTypes.string,
};

export default DropzoneComponent;

export const DroppableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`;

const thumbsContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #EAEAEA",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const InnerContainer = styled.div`
  height: 8rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;
