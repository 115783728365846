import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Web3ResumeContainer from "../../components/shared/Form/Web3ResumeContainer";
import IndividualFormContainer from "../../components/shared/Form/IndividualFormContainer";
import { fetchLatestProfileData } from "../../utils/FetchLatestData";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import TemplateCard from "../../components/shared/Templates/TemplateCard";

const Template = (props) => {
  const [templateType, setTemplateType] = useState();
  return (
    <Container>
      {(() => {
        if (!templateType) {
          return (
            <>
              <ButtonContainer
                span
                biglink
                heading="Templates"
                description="Select a template and create a workflow"
                icon={"view_quilt"}
              />

              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={6}>
                    <TemplateCard
                      image="https://res.cloudinary.com/monday-blogs/fl_lossy,f_auto,q_auto/wp-blog/2020/12/workflow-diagram-2-1.jpg"
                      name="Supply Chain"
                      description="Make your own process using basic data, phases, and other
            information."
                      setTemplateType={setTemplateType}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <TemplateCard />
                  </Grid>
                  <Grid item xs={6}>
                    <TemplateCard />
                  </Grid>
                  <Grid item xs={6}>
                    <TemplateCard />
                  </Grid> */}
                </Grid>
              </Box>
            </>
          );
        } else {
          return (
            <Fragment>
              {(() => {
                switch (templateType) {
                  case 1:
                    return (
                      <Fragment>
                        {/* <ButtonContainer
                          heading="User Profile"
                          description="Add, remove or edit your personal details here"
                          icon="badge"
                        /> */}
                        {/* <Web3ResumeContainer
                          view={view}
                          setView={setView}
                          profileType={profileType}
                          {...props}
                        /> */}
                      </Fragment>
                    );
                  default:
                    return null;
                }
              })()}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default Template;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  // min-height: 40rem;
  height: auto;
`;
