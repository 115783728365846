import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShareIcon from "@mui/icons-material/Share";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, Modal } from "@mui/material";
import { Text } from "../../../pages/JobListings/JobComponent";
import { Heading } from "../Typography/Typo";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { FieldWrapper, FlexSpaceBetweenContainer } from "./CustomProfile";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

const CustomProfileHeader = ({
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  isPublic,
  setIsPublic,
  setCurrentField,
  decryptType,
  isDecrypted,
  setEditOpen,
  setEditType,
  setShareOpen,
  setContactOpen,
  isViewAssignedItem,
  ownerShipDetails,
  uniqueNFTsTotalToken,
  limitedSFTsTotalToken,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const openProfileMenu = Boolean(profileMenuAnchor);

  const handleClickProfileMenu = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setProfileMenuAnchor(null);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleContactOpen = () => {
    setEditType("");
    if (!isDecrypted) {
      decryptType("all");
      setShowDecryption(true);
      setEditType("contact");
    } else {
      setContactOpen(true);
    }
  };

  const handleEncryption = () => {
    setEditType("");
    decryptType("all");
    isDecrypted ? setShowEncryption(true) : setShowDecryption(true);
  };

  const handleVerification = () => {
    setIsPublic((value) => !value);
  };

  const handleEditProfile = () => {
    setEditType("");
    if (!isDecrypted) {
      decryptType("all");
      setShowDecryption(true);
      setEditType("basicEdit");
    } else {
      setEditOpen((value) => !value);
    }
  };

  const handleShare = () => {
    setEditType("");
    if (!isDecrypted) {
      decryptType("all");
      setShowDecryption(true);
      setEditType("shareOpen");
    } else {
      setShareOpen((value) => !value);
    }
  };

  return (
    <Fragment>
      <SectionContainer>
        <AbsoluteContainer>
          <FlexSpaceBetweenContainer style={{ gap: "0.5rem" }}>
            <DesktopViewIconWrapper style={{ gap: "0.5rem" }}>
              <IconWrapper>
                <IconButton
                  onClick={() => {
                    setShareOpen((value) => !value);
                  }}
                >
                  <ShareIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                </IconButton>
              </IconWrapper>
            </DesktopViewIconWrapper>
          </FlexSpaceBetweenContainer>
        </AbsoluteContainer>

        <Banner>
          <SectionInnerContainer>
            <ContentContainer>
              <NameContainer>
                <FieldWrapper>
                  <Heading
                    primary
                  >{`${profileData?.basicData?.Brand?.data?.data}`}</Heading>

                  {isViewAssignedItem &&
                  profileData?.basicData?.Brand?.isPublic &&
                  isPublic ? (
                    <VerifiedStatusContainer
                      isPublic={isPublic}
                      fieldData={profileData?.basicData?.Brand}
                      fieldName="Product Name"
                      fieldId={{ id: "firstName" }}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      isDecrypted={profileData?.basicData?.Brand?.isPublic}
                      setCurrentField={setCurrentField}
                      setShowEncryption={setShowEncryption}
                      setShowDecryption={setShowDecryption}
                      encryptedType={
                        profileData?.basicData?.Brand?.data?.encryptionType
                      }
                      // style={{ top: "70%", position: "absolute", left: "26%" }}
                    />
                  ) : !isViewAssignedItem ? (
                    <VerifiedStatusContainer
                      isPublic={isPublic}
                      fieldData={profileData?.basicData?.Brand}
                      fieldName="Product Name"
                      fieldId={{ id: "firstName" }}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      isDecrypted={profileData?.basicData?.Brand?.isPublic}
                      setCurrentField={setCurrentField}
                      setShowEncryption={setShowEncryption}
                      setShowDecryption={setShowDecryption}
                      encryptedType={
                        profileData?.basicData?.Brand?.data?.encryptionType
                      }
                      // style={{ top: "70%", position: "absolute", left: "26%" }}
                    />
                  ) : (
                    <></>
                  )}
                </FieldWrapper>
              </NameContainer>

              <FieldWrapper>
                <NameContainer>
                  <Text lighter>
                    {profileData?.basicData["Hanger Type"].data.data}
                  </Text>

                  {isViewAssignedItem &&
                  profileData?.basicData["Hanger Type"]?.isPublic &&
                  isPublic ? (
                    <VerifiedStatusContainer
                      isPublic={isPublic}
                      fieldData={profileData?.basicData["Hanger Type"]}
                      fieldName="Lives in"
                      fieldId={{ id: "city" }}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      setShowDecryption={setShowDecryption}
                      isDecrypted={
                        profileData?.basicData["Hanger Type"]?.isPublic
                      }
                      encryptedType={
                        profileData?.basicData["Hanger Type"]?.data
                          ?.encryptionType
                      }
                      setCurrentField={setCurrentField}
                      setShowEncryption={setShowEncryption}
                      // style={{ top: "60%", position: "absolute", left: "24%" }}
                    />
                  ) : !isViewAssignedItem && isPublic ? (
                    <VerifiedStatusContainer
                      isPublic={isPublic}
                      fieldData={profileData?.basicData["Hanger Type"]}
                      fieldName="Lives in"
                      fieldId={{ id: "city" }}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      setShowDecryption={setShowDecryption}
                      isDecrypted={
                        profileData?.basicData["Hanger Type"]?.isPublic
                      }
                      encryptedType={
                        profileData?.basicData["Hanger Type"]?.data
                          ?.encryptionType
                      }
                      setCurrentField={setCurrentField}
                      setShowEncryption={setShowEncryption}
                      // style={{ top: "60%", position: "absolute", left: "24%" }}
                    />
                  ) : (
                    <></>
                  )}
                </NameContainer>
              </FieldWrapper>
            </ContentContainer>
            {/* <ContentContainer style={{ gap: "0.8rem", marginTop: "4rem" }}>
              <NameContainer>
                <Text lighter color="#6d6d6d">
                  {
                    profileData?.basicData["Unique Identifier / Batch ID"].data
                      .data
                  }
                </Text>

                {isViewAssignedItem &&
                profileData?.basicData["Unique Identifier / Batch ID"]
                  ?.isPublic &&
                isPublic ? (
                  <VerifiedStatusContainer
                    isPublic={isPublic}
                    fieldData={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                    }
                    fieldName="Lives in"
                    fieldId={{ id: "city" }}
                    setShowAction={setShowAction}
                    setVerification={setVerification}
                    setShowDecryption={setShowDecryption}
                    isDecrypted={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                        ?.isPublic
                    }
                    encryptedType={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                        ?.data?.encryptionType
                    }
                    setCurrentField={setCurrentField}
                    setShowEncryption={setShowEncryption}
                    // style={{ top: "60%", position: "absolute", left: "24%" }}
                  />
                ) : !isViewAssignedItem && isPublic ? (
                  <VerifiedStatusContainer
                    isPublic={isPublic}
                    fieldData={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                    }
                    fieldName="Lives in"
                    fieldId={{ id: "city" }}
                    setShowAction={setShowAction}
                    setVerification={setVerification}
                    setShowDecryption={setShowDecryption}
                    isDecrypted={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                        ?.isPublic
                    }
                    encryptedType={
                      profileData?.basicData["Unique Identifier / Batch ID"]
                        ?.data?.encryptionType
                    }
                    setCurrentField={setCurrentField}
                    setShowEncryption={setShowEncryption}
                    // style={{ top: "60%", position: "absolute", left: "24%" }}
                  />
                ) : (
                  <></>
                )}
              </NameContainer>
            </ContentContainer> */}
          </SectionInnerContainer>
        </Banner>
      </SectionContainer>
    </Fragment>
  );
};

export default CustomProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 2.5rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TokenContainer = styled.div`
  margin-top: 40%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: 1368px) {
    margin-top: 30%;
  }
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const Hai = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 2;
`;

export const AbsoluteTokenContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const MobileViewIconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const DesktopViewIconWrapper = styled.div`
  // display: flex;
  // @media (max-width: 768px) {
  //   display: none;
  // }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
