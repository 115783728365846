import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";

export default function WorkflowCard({
  setTemplateType,
  name,
  image,
  description,
  setCurrentWorkflow,
  setOpenUseWorkFlow,
}) {
  return (
    <Card
      sx={{ maxWidth: 345 }}
      // onClick={() => {
      //   setTemplateType(1);
      // }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            onClick={() => {
              setCurrentWorkflow({
                id: "#9086890",
                name: name,
                action: "use",
              });
              setOpenUseWorkFlow(true);
            }}
          >
            Use
          </Button>
          {/* <Button size="small">Edit</Button> */}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
