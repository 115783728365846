import { LinearProgress, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Web3 from "web3";

import { reviewVerification } from "../../../api/API";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { CustomButton } from "../fields/Button/CustomButton";
import { ButtonWrapper, ColumnContainer } from "../Form/ProductFormContainer";
import { ColumnLoadContainer } from "../Profile/ShareConfirm";
import { SubHeading, Text } from "../Typography/Typo";
import VerificationCard from "./VerificationCard";
import { Gif } from "./VerificationStepper";
import LoadingGif from "../../../assets/images/loading.gif";
import PDFViewer from "../../../PDFViewer";
import PDFImageSlider from "./PDFImageSlider";
import { ArrowBody, ArrowsContainer } from "../Form/StageForm/StageSliderView";
import * as API from "../../../api/API";
import * as encryption from "../../../utils/encryption";
import { getSignature } from "../../../utils/getSingature";
//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const VerificationSingleView = ({ data }) => {
  let key = Object.keys(data?.data);
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const currentUser = useSelector((state) => state.currentUser);
  const [openImage, setImageOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionState, setActionState] = useState("Error");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleReviewVerification = async (data) => {
    data.verificationStatus === "approve"
      ? setActionState("Approving")
      : setActionState("Rejecting");
    setLoading(true);
    try {
      const { blockchain, signatureObj } = await getSignature(
        currentUser,
        authUser,
        localStorage.secretKey
      );

      const response = await reviewVerification({
        path: {
          vId: data?.data?.profilePublicKey,
        },
        query: {
          status: data?.verificationStatus,
        },
        body: {
          dataType: data?.data?.dataType,
          id: data?.data?._id,
          reviewerPublicKey: currentUser.publicKey,
          reviewerAlias: currentUser?.alias,
          reviewerImage: currentUser?.image,
          network: blockchain,
          signature: signatureObj,
        },
      });
      if (response?.data?.code === 201) {
        let message =
          data.verificationStatus === "approve"
            ? "Effectively Checked!"
            : "Effectively unconfirmed!";
        enqueueSnackbar(message, {
          variant: "success",
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(false);
      history.push("/dashboard/verifications");
    }
  };

  return (
    <>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                data?.data?.imageLogo ||
                data?.data?.itemImage ||
                data?.data?.toString().split(",")[0] ||
                data?.data
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      {loading ? (
        <CenterContainer>
          <ColumnLoadContainer>
            <Gif src={LoadingGif} style={{ width: "15rem" }} />
            <LoadingContainer>
              <BorderLinearProgress />
              <Text primary small lighter style={{ width: "17.5rem" }}>
                {actionState} User Verification Request
                <span className="loading"></span>
              </Text>
            </LoadingContainer>
          </ColumnLoadContainer>
        </CenterContainer>
      ) : (
        <Container>
          <ColumnContainer>
            <SubHeading primary> Review Unconfirmed Data</SubHeading>
            <Text small lighter color="#6b6b6b">
              Review all Unconfirmed data and Details
            </Text>
          </ColumnContainer>

          <Header>
            <SubHeading primary style={{ textTransform: "capitalize" }}>
              {data?.dataType?.name ? data?.dataType?.name : data?.dataType?.id}
            </SubHeading>
            <VerificationCard
              onClick={setImageOpen}
              type={
                data?.data.courseName ||
                data?.data.position ||
                data?.data.itemName
                  ? "Education"
                  : ""
              }
              dataType={data?.verificationType && data.verificationType}
              title={
                data.data.courseName ||
                data.data.position ||
                data.data.itemName ||
                data.data?.toString().split(",") ||
                data.data ||
                key[0]
              }
              logoImage={
                data?.data?.imageLogo
                  ? data?.data?.imageLogo
                  : data?.data?.itemImage
              }
              isPublic={true}
              startDate={Number(data?.data?.startDate)}
              institution={
                data?.data?.institutionName
                  ? data?.data?.institutionName
                  : data?.data?.company
                  ? data?.data?.company
                  : data?.data?.issuer
              }
              endDate={Number(data?.data?.endDate)}
              description={data?.data?.description || data?.data[key[0]]}
            />
          </Header>
          {data?.file?.length > 0 && (
            <ColumnContainer style={{ gap: "2rem" }}>
              <ColumnContainer>
                <SubHeading primary>Verify Uploaded Documents</SubHeading>
                <Text small lighter color="#6b6b6b">
                  Cross-check user details with the documents uploaded
                </Text>
              </ColumnContainer>
              <PDFImageSlider data={data?.file} />
              <ArrowsContainer>
                <ArrowBody className="prev">
                  <ArrowBackIosNewIcon
                    className="prev"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
                <ArrowBody className="next">
                  <ArrowForwardIosIcon
                    className="next"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
              </ArrowsContainer>
            </ColumnContainer>
          )}

          <ButtonWrapper style={{ gap: "1rem" }}>
            <CustomButton
              login
              rejectClass
              text="Reject"
              disabled={loading}
              type="button"
              onclick={() => {
                handleReviewVerification({
                  verificationStatus: "reject",
                  data: data,
                });
              }}
            />
            <CustomButton
              login
              acceptClass
              text="Approve"
              type="button"
              disabled={loading}
              onclick={() => {
                handleReviewVerification({
                  verificationStatus: "approve",
                  data: data,
                });
              }}
            />
          </ButtonWrapper>
        </Container>
      )}
    </>
  );
};

export default VerificationSingleView;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 1rem;
  min-height: 18rem;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #c3d0e181;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 2rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
