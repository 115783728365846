import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { urlToHash } from "../../../utils/ipfs";
import { GetIPFSData } from "../../../services/IpfsService";
// import { RequestVerification } from "./RequestVerification";
import { SubHeading, Text } from "../Typography/Typo";
import { IconButton } from "@mui/material";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getUserSession, retrieveTokens } from "../../../api/API";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../../utils/encryption";
import store from "../../../redux/reducers";
import { ADD_IPFS_DATA } from "../../../redux/constants/ActionTypes";
import UserProfileEdit from "../EditForms/UserProfileEdit";
import EditForm from "../EditForms/EditForm";
import CustomProfileHeader from "./CustomProfileHeader";
import { SectionContainer } from "../ProductProfile/ProductProfileHeader";
import { Slider } from "../Form/StageForm/StageSliderView";
import { CustomSlider } from "./CustomSlider";
import { ShareConfirm } from "../Profile/ShareConfirm";
import { ProductShareConfirm } from "../ProductProfile/ProductShareConfirm";
// import { ShareConfirm } from "./ShareConfirm";
// import ContactInfo from "./ContactInfo";

const CustomProfile = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });

  const [decryptType, setDecryptType] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [currentField, setCurrentField] = useState({});
  const [isPublic, setIsPublic] = useState(false);
  const [editType, setEditType] = useState("");
  const [viewVerifiedData, setViewVerifiedData] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [uniqueNFTsTotalToken, setUniqueNFTsTotalToken] = useState(0);
  const [limitedSFTsTotalToken, setLimitedSFTsTotalToken] = useState(0);

  // TODO: Want to do a alternative way without getting the latestIpfsData
  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  const handleContactClose = () => {
    setContactOpen(false);
  };

  if (latestIpfsData) {
    var shareData = {
      uniqueId:
        latestIpfsData.basicData["Unique Identifier / Batch ID"]?.data?.data,
      itemName: latestIpfsData.basicData["Brand"]?.data?.data,
    };
  }

  return (
    <Fragment>
      {/* {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
        />
      )} */}
      {/* {showDecryption && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to decrypt"}
          setShowAction={setShowDecryption}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          //use the prop below to get the secret key
          onSecretKey={decryptData}
        />
      )} */}
      {/* {shareOpen && (
        <ShareConfirm
          data={shareData}
          message={"Copy Profile Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your profile"}
          link={`http://explorer.deprofile.io/profile/${currentUser.publicKey}`}
        />
      )} */}
      {shareOpen && (
        <ProductShareConfirm
          data={shareData}
          message={"Copy Product Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your product"}
          link={`http://explorer.deprofile.io/depro/${currentUser.alias}`}
        />
      )}{" "}
      <Container>
        <CustomProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          decryptType={setDecryptType}
          isDecrypted={isDecrypted}
          setEditOpen={setEditOpen}
          setEditType={setEditType}
          setShareOpen={setShareOpen}
          setContactOpen={setContactOpen}
          uniqueNFTsTotalToken={uniqueNFTsTotalToken}
          limitedSFTsTotalToken={limitedSFTsTotalToken}
        />
        <SectionContainer>
          <FlexColumnContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>Stages</SubHeading>
            </FlexSpaceBetweenContainer>
            <CustomSlider
              productData={latestIpfsData?.stageData}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setViewVerifiedData={setViewVerifiedData}
              setCurrentField={setCurrentField}
              setShowDecryption={setShowDecryption}
              // setShowEncryption={encryptData}
              isPublic={isPublic}
              // setOpenEditedDetails={setOpenEditedDetails}
              // setEditedDetails={setEditedDetails}
            />
            {latestIpfsData?.stageData.length > 0 && (
              <ArrowsContainer>
                <ArrowBody className="prev">
                  <ArrowBackIosNewIcon
                    className="prev"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
                <ArrowBody className="next">
                  <ArrowForwardIosIcon
                    className="next"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
              </ArrowsContainer>
            )}
          </FlexColumnContainer>
        </SectionContainer>
      </Container>
    </Fragment>
  );
};

export default CustomProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const ArrowBody = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  padding: 0.4rem;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
`;

export const ArrowsContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const ViewButton = styled(Link)`
  text-decoration: none;
  padding: 0.3rem 1rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
`;

export const ConfigureTokenContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  // width: auto;
  // z-index: 33;
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
