import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Box, Card, CardContent, CardMedia, Modal } from "@mui/material";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { Text } from "../../components/shared/Typography/Typo";
import { getObject } from "../../api/API";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "../../services/IpfsService";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { Error, ErrorContainer } from "../Claim/ClaimView";
import ErrorBG from "../../assets/images/not-found.gif";
import { SearchComponent } from "../JobListings/SearchComponent";
import { IconWrapper } from "../../university-pages/Certificates/IssueCertificateForm";
import { IconButton, Pagination } from "@mui/material";
import AddProfile from "./AddProfile";
import CreateProfile from "./CreateProfile";
import ProfileListCard, { AccessButton } from "./ProfileListCard";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { ViewButton } from "../../components/shared/Profile/UserProfile";
import LoadingView from "../../components/shared/loading/LoadingView";
import ViewPagination from "../../components/shared/Pagination/Pagination";

const ViewProfileList = () => {
  const { id } = useParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const [search, setSearch] = useState("");
  const [date, onDateChange] = useState();
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [niftronLoading, setNiftronLoading] = useState(false);
  // const [{ searchData }, dispatch] = useStateValue();

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  // TODO : this is want to happen through server side
  const fetchProducts = async (data) => {
    setMainPageLoading(true);
    let arr = [];

    for (let i = 0; i < data?.length; i++) {
      try {
        const response = await getObject({
          aId: data[i].publicKey,
        });
        if (response !== undefined) {
          // if (response.data.data[0].latestIpfsUrl) {
          //   const hash = urlToHash(
          //     response.data.data[0].latestIpfsUrl?.ipfsUrl
          //   );
          //   const ipfsData = await GetIPFSData(hash);
          //   ipfsData["publicKey"] = data[i]?.publicKey;
          //   ipfsData["encryptedSecretKey"] = data[i]?.encryptedSecretKey;
          //   arr.push(ipfsData);
          // } else {
          //   arr.push(response.data.data[0]);
          // }

          arr.push(response.data.data[0]);
        }
      } catch (e) {
        console.log(e);
      }
    }
    console.log("arr : ", arr);
    setLatestIpfsData(arr);
    setMainPageLoading(false);
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentProductList =
    latestIpfsData &&
    latestIpfsData.length > 0 &&
    latestIpfsData.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    currentUser?.profiles && fetchProducts(currentUser?.profiles);
  }, [currentUser?.profiles]);

  return (
    <Container>
      {showAction && (
        <AddProfile show={showAction} setShowAction={setShowAction} />
      )}
      {showCreateProduct && (
        <CreateProfile
          show={showCreateProduct}
          setShowAction={setShowCreateProduct}
        />
      )}
      <SearchComponent
        searchValue={search}
        onSearchChange={setSearch}
        dateValue={date}
        placeholder="Search profiles"
        onDateChange={(e) => {
          onDateChange(e);
          setSearch(moment(e).format("L"));
        }}
      />

      <IconContainer>
        <AccessButton
          onClick={() => {
            setShowCreateProduct(true);
          }}
        >
          {" "}
          Create
        </AccessButton>
        &nbsp;
        <AccessButton
          onClick={() => {
            setShowAction(true);
          }}
        >
          {" "}
          Import
        </AccessButton>
      </IconContainer>

      {(() => {
        if (mainPageLoading) {
          return (
            <LoadingView
              additionalStyles={{ zIndex: 9999, position: 'absolute', top: 0 }}
            />
          );
        } else if (latestIpfsData <= 0) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="470px" />
              <Text style={{ marginBottom: "1rem" }} primary>
                No Purchased Profiles Found!
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              {/* <GridContainer> */}
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {latestIpfsData &&
                    currentProductList
                      .slice(0)
                      .reverse()
                      .filter((item) => {
                        if (search === "" && date === undefined) {
                          return item;
                        } else if (
                          item.name &&
                          item.name.data.data
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        } else if (
                          item.productName &&
                          item.productName.data.data
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        } else if (
                          item.alias &&
                          item.alias
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return item;
                        } else if (
                          item.createdAt &&
                          moment(item.createdAt).format("L").includes(search)
                        ) {
                          return item;
                        }
                      })
                      .map((item, key) => {
                        return (
                          <Grid item xs={4}>
                            {" "}
                            <ProfileListCard
                              data={item}
                              type={
                                item?.alias
                                  ? item.profileType
                                  : item?.profileType?.data?.data
                              }
                              key={key}
                              token
                              setMainPageLoading={setMainPageLoading}
                            />{" "}
                          </Grid>
                          // <ProfileListCard
                          //   data={item}
                          //   type={
                          //     item?.alias
                          //       ? item.profileType
                          //       : item?.profileType?.data?.data
                          //   }
                          //   key={key}
                          //   token
                          //   setMainPageLoading={setMainPageLoading}
                          // />
                        );
                      })}
                </Grid>
              </Box>
              {/* </GridContainer> */}
              {search === "" && (
                <ViewPagination
                  itemsPerPage={itemsPerPage}
                  totalItems={latestIpfsData && latestIpfsData.length}
                  paginate={paginate}
                />
              )}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
};

export default ViewProfileList;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const GridContainer = styled.div`
  width: 100%;
  gap: 2rem;
  margin: 1rem 0rem;
  display: flex;
  flex-wrap: wrap;
`;
